import React from 'react';
import { Link } from 'gatsby';

import {
  FaTwitter,
  FaTelegram,
  FaMedium,
  FaGithub,
  FaDiscord,
} from 'react-icons/fa';
import { Container, Row, Col } from '../../reusecore/Layout';
import Box from '../../reusecore/Box';
import { List, ListItem } from '../../reusecore/List';
import FullYear from '../../components/fullyear';

import footerLogo from '../../assets/svg/footer_logo.svg';
import FooterWrapper from './styled';

const Footer = () => {
  return (
    <FooterWrapper>
      <Container>
        <Row className='footer-widgets'>
          <Col className='lg-4 sm-12 xs-12'>
            <Box className='vufi-logo'>
              <img src={footerLogo} alt='Vufi footer logo' />
            </Box>
          </Col>
          <Col className='lg-4 sm-6 xs-12'>
            <Box className='middle'>
              <List className='footer-menu'>
                <ListItem>
                  <FullYear text='VuFi.Finance' />
                </ListItem>
              </List>
            </Box>
          </Col>
          <Col className='lg-4 sm-6 xs-12'>
            <Box className='right footer-social-links'>
              <Link to='https://twitter.com/VufiFinance' target='_blank'>
                <FaTwitter />
              </Link>
              <Link to='https://t.me/vufifinance' target='_blank'>
                <FaTelegram />
              </Link>
              <Link to='https://vufifinance.medium.com/' target='_blank'>
                <FaMedium />
              </Link>
              <Link to='https://github.com/VuFiFinance' target='_blank'>
                <FaGithub />
              </Link>
              <Link to='https://discord.com/invite/tm8bV9rSG2' target='_blank'>
                <FaDiscord />
              </Link>
            </Box>
          </Col>
        </Row>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
