import styled from 'styled-components';

const FooterWrapper = styled.div`
  background: #e42b39;
  a {
    &:hover {
      color: #fff;
    }
  }

  .vufi-logo {
    display: flex;
    align-items: center;
    color: white;
    font-size: 14px;
    line-height: 35px;

    @media only screen and (max-width: 769px) {
      margin-bottom: 1rem;
    }

    &:hover {
      color: #ffe256;
    }

    .company-desc {
      line-height: 26px;
      margin-bottom: 0px;
      margin-left: 12px;
      white-space: nowrap;
    }
  }

  .footer-widgets {
    padding: 42px 0px;
    border-bottom: 1px solid #c5002d;
    a {
      color: white;
      font-size: 14px;
      line-height: 35px;

      &:hover {
        color: #ffe256;
      }
    }

    img {
      width: 180px;
    }

    .footer-menu {
      color: white;
      display: flex;
      justify-content: center;

      @media only screen and (max-width: 769px) {
        margin-bottom: 1rem;
        justify-content: flex-start;
      }

      .list__item {
        padding: 4px 8px;
      }
    }
  }

  .footer-social-links {
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 578px) {
      margin-bottom: 1rem;
      justify-content: flex-start;
    }

    a {
      font-size: 24px;
      font-weight: 700;
      color: #fff;
      width: 40px;
      height: 40px;
      background: #ca002f;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 0px 6px;

      &:hover {
        color: #ffe256;
      }
    }
  }

  .copyright-text {
    display: flex;
    justify-content: space-between;
    padding: 42px 0 50px 0px;

    .text {
      margin: 0px;
      font-size: 14px;
      color: white;
    }

    .right-link {
      a {
        color: white;
        padding: 0px 32px;
        border-right: 1px solid #c5002d;

        &:hover {
          color: #ffe256;
        }
      }

      &:last-child {
        a {
          border-right: 0px;
        }
      }
      @media (max-width: 576px) {
        a {
          padding: 0px 20px;
        }

        &:first-child {
          a {
            padding-left: 0px;
          }
        }
      }
      @media (max-width: 420px) {
        a {
          padding: 0px 12px;
        }
      }
      @media (max-width: 374px) {
        a {
          padding: 0px 4px;
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .copyright-text {
      flex-direction: column-reverse;

      .text {
        margin-top: 8px;
      }
    }
  }
`;

export default FooterWrapper;
