import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

::selection {
    background: #333333;
    color: #ffffff;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *:focus {
    outline: none;
  }

html{
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

body,html {
    
    margin: 0px;

}
body {
  font-size: 16px;
  line-height: 28px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
  color: ${props => (props.theme.bodyColor ? props.theme.bodyColor : '#BEABDF')};
  overflow-x: hidden !important;
  font-weight: 400;
  margin: 0;
  padding: 0;
  background: #FFFFFF;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Roboto Slab', sans-serif;
}

p{
  font-family: 'Lato', sans-serif;
  margin: 0 0 15px 0;
}

a{
    text-decoration: none;
  }
  
  img{
    max-width: 100%;
  }


section{
    position: relative;
}

.text-center{
  text-align: center;
}

button, .btn{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 170px;
  height: 55px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background: #E42B39;
  text-transform: uppercase;
  border-radius: 5px;
  font-weight: 600;
  padding: 16px 22px;
  color: #ffffff;
}
.btn {
  &.btn-yellow {
    background: #FFE256;
    color: #4D2100;
  }
  &.disabled-btn {
    pointer-events: none;
    background-color: #cccccc;
  }
  svg {
    display: inline-block;
    vertical-align: middle;
  }
  &.right-icon {
    svg {
      margin-left: 20px;
    }
  }
}
`;

export default GlobalStyle;
