import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Navigation from 'sections/Navigation';
import Footer from 'sections/Footer';
import SEO from './seo';

const Layout = ({ children, title }) => {
  return (
    <>
      <SEO title={title} />
      <Navigation />
      <Container>{children}</Container>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

Layout.defaultProps = {
  title: '',
};

export default Layout;

const Container = styled.main`
  padding-top: 109px;

  @media only screen and (max-width: 768px) {
    padding-top: 74px;
  }
`;
