export const menuItems = [
  {
    name: 'Whitepaper',
    path: 'https://docs.vufi.finance/',
  },
  {
    name: 'Medium',
    path: 'https://vufifinance.medium.com/',
  },

  // {
  //   name: 'Github',
  //   path: 'https://github.com/VuFiFinance',
  // },
  {
    name: 'NFT',
    path: 'https://vufigang.com/',
  },
];
