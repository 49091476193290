const theme = {
  black: '#333333',
  white: '#ffffff',
  primaryColor: '#004085',
  primaryHoverColor: '#cce5ff',
  secondaryColor: '#383d41',
  headingColor: '#FFFFFF',
  bodyColor: '#181818',
  linkColor: '#1F2AD5',
  NavBG: '#FFFFFF',
  NavBGSolid: '#290E59',
  menuHoverColor: '#ffa33d',
};
export default theme;
