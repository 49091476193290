import React from 'react';
import Text from '../reusecore/Text';

const FullYear = ({ text }) => (
  <Text>
    &copy; {new Date().getFullYear()} {text}
  </Text>
);

export default FullYear;
